<template>
    <div>
      <strong>Nombre completo: {{ props.row.cliente }}</strong><br>
      <b-tag>ID Operación: {{ props.row.id }}</b-tag>
      <br>
      <small>Fecha: {{ formatDate(props.row.fecha_hora) }}</small><br>
      <small>Tipo de identidad: {{ typeDocFormatting(props.row.identificacion) }}</small><br>
      <small>Documento de identidad: {{ numDocFormatted(props.row.identificacion) }}</small><br>
      <small>Ultima Operación: {{ props.row.lastEvent }}</small><br>
      <small>Codigo respuesta ultima operación: {{ props.row.lastOperationCode }}</small><br>
      <small>Respuesta ultima operación: {{ props.row.responseLastEvent }}</small><br>
      <!--<small>Score biometria: {{ (props.row.score * 100).toFixed(2) }}%</small><br>
      <small>Score documento: {{ (props.row.scoredocument * 100).toFixed(2) }}%</small><br>-->
      <small>Score configurado: {{ (props.row.scoreconfigured * 100).toFixed(2) }}%</small><br>
      <small>Score total: {{ (props.row.scoretotal * 100).toFixed(2) }}%</small><br>
      <b-tag :type="getStatusTag(props.row.estado)">
        Estado: {{ props.row.estado }}
      </b-tag>
    </div>
  </template>
  
  <script>
  import {
    dateFormatting, getStatusTagFinan,numDocFormatted,typeDocFormatting
  } from "../../../assets/config/utilities";
  import {STATES} from "../../../assets/config/constants/GeneralConstants";
  
  export default {
    name: "FirstCardDetail",
    props: [
      'props'
    ],
    methods: {
      formatDate(date) {
        return dateFormatting(date);
      },
      getStatus(status) {
        return STATES[status].label
      },
      getStatusTag(status) {
        return getStatusTagFinan(status);
      },
      numDocFormatted(user){
        return numDocFormatted(user)
      },
      typeDocFormatting(user){
        return typeDocFormatting(user)
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>